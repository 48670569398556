import { resolveNoneComputedStyle } from "@devowl-wp/resolve-none-computed-style";
import { HTML_ATTRIBUTE_WAS_PREVIOUSLY_STRICT_HIDDEN, HTML_ATTRIBUTE_RESET_PARENT, HTML_ATTRIBUTE_RESET_PARENT_VALUE, HTML_ATTRIBUTE_RESET_PARENT_IS_RATIO_CONTAINER } from ".."; // TODO: make configurable

const KNOWN_RATIO_CLASSES = [// General
"-aspect-ratio", // Gutenberg
"wp-block-embed__wrapper", // Cornerstone
"x-frame-inner", // Fusion
"fusion-video", // Oxygen
"video-wrapper"];
const RESET_STYLE_IMPORTANT = {
  "max-height": "initial",
  height: "auto",
  padding: 0,
  "aspect-ratio": "initial",
  "box-sizing": "border-box"
};
const RESET_STYLE = {
  width: "100%"
};
const MEMORIZE_OLD_STYLE = "consent-cb-memo-style";
/**
 * Example: Vimeo responsive video.
 *
 * ```html
 * <div style="padding:56.25% 0 0 0;position:relative;">
 *  <iframe style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe>
 * </div>
 * ```
 *
 * Example 2: Oxygen responsive video wrapper
 *
 * ```html
 * <div style="aspect-ratio:16/9">
 *  <iframe></iframe>
 * </div>
 * ```
 */

function isPotentialVisualWithinResponsiveContainer(node) {
  const {
    parentElement
  } = node;

  if (!parentElement) {
    return false;
  }

  const parentComputedStyle = getComputedStyle(parentElement);

  if (/\d+\s*\/\s*\d+/g.test(parentComputedStyle["aspectRatio"])) {
    return true;
  }

  const {
    position: nodePosition
  } = getComputedStyle(node);
  const {
    position: parentPosition
  } = parentComputedStyle;
  return nodePosition === "absolute" && parentPosition === "relative" && parentElement.style.paddingTop.indexOf("%") > -1;
}
/**
 * Modify parent element with some styles, e. g. Elementor puts some padding into it.
 * We need to enforce the style via inline-style because some themes use `!important` in their
 * classes, too.
 *
 * @param state If `true`, the reset-style will be forced and vice-versa
 */


function probablyResetParentContainerForVisual(contentBlocker, state) {
  var _parentElement$parent;

  const {
    parentElement
  } = contentBlocker;
  const iterateParents = [parentElement, parentElement === null || parentElement === void 0 ? void 0 : parentElement.parentElement, parentElement === null || parentElement === void 0 ? void 0 : (_parentElement$parent = parentElement.parentElement) === null || _parentElement$parent === void 0 ? void 0 : _parentElement$parent.parentElement].filter(Boolean); // Go max three levels upwards

  for (const parent of iterateParents) {
    // Calculate if this HTML element is a ratio container and can be reset
    if (!parent.hasAttribute(HTML_ATTRIBUTE_RESET_PARENT_IS_RATIO_CONTAINER)) {
      const foundRatioClass = KNOWN_RATIO_CLASSES.filter(c => parent.className.indexOf(c) > -1).length > 0;
      const isFirstItemWithinResponsiveContainer = parent === parentElement && isPotentialVisualWithinResponsiveContainer(contentBlocker);
      const isRatioContainer = isFirstItemWithinResponsiveContainer || foundRatioClass || [0, "0%", "0px"].indexOf(resolveNoneComputedStyle(parent, "height")) > -1;
      parent.setAttribute(HTML_ATTRIBUTE_RESET_PARENT_IS_RATIO_CONTAINER, isRatioContainer ? "1" : "0");
    }

    if (state && parent.getAttribute(HTML_ATTRIBUTE_RESET_PARENT_IS_RATIO_CONTAINER) === "1") {
      // Check the `display:none!important` style
      const previouslyStrictHidden = parent.hasAttribute(HTML_ATTRIBUTE_WAS_PREVIOUSLY_STRICT_HIDDEN);
      let style = parent.getAttribute("style") || "";
      parent.removeAttribute(HTML_ATTRIBUTE_WAS_PREVIOUSLY_STRICT_HIDDEN);

      if (!previouslyStrictHidden) {
        style = style.replace(/display:\s*none\s*!important;/, "");
      } // Apply our new styles with memorization


      parent.setAttribute(HTML_ATTRIBUTE_RESET_PARENT, HTML_ATTRIBUTE_RESET_PARENT_VALUE);
      parent.setAttribute(MEMORIZE_OLD_STYLE, style);

      for (const property in RESET_STYLE_IMPORTANT) {
        parent.style.setProperty(property, RESET_STYLE_IMPORTANT[property], "important");
      }

      for (const property in RESET_STYLE) {
        parent.style.setProperty(property, RESET_STYLE[property]);
      } // Special use case: Positioning


      if (window.getComputedStyle(parent).position === "absolute") {
        parent.style.setProperty("position", "static", "important");
      }
    } else if (!state && parent.hasAttribute(HTML_ATTRIBUTE_RESET_PARENT)) {
      parent.setAttribute("style", parent.getAttribute(MEMORIZE_OLD_STYLE) || "");
      parent.removeAttribute(MEMORIZE_OLD_STYLE);
      parent.removeAttribute(HTML_ATTRIBUTE_RESET_PARENT);
    }
  }
}

export { isPotentialVisualWithinResponsiveContainer, probablyResetParentContainerForVisual };